import React from 'react';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { matchPath } from 'react-router';
import TagManager from 'react-gtm-module'

class Tagging extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      siteLoaded: false,
    };
  }

  componentDidMount() {
    addObserversForProducts(this.props.sitecoreContext);

    // Load only
    let pageCategory = this.getCurrentPageCategory();

    if (!this.state.siteLoaded) {
      window.dataLayer.push({
        event: 'variablePage',
        brand: 'CAD',
        language: 'fr',
        country: 'FR',
        siteTypeLevel: 'main',
        pageCategory: pageCategory,
      });

      this.setState({ siteLoaded: true });
    }

    this.productDetailsPage();
    if (pageCategory === 'content page::article') this.readArticleEvent(this.props.sitecoreContext.route.displayName);

    if (pageCategory === 'product detail page') {
      let obsAdded = false;
      const interval = setInterval(() => {
        if (document.querySelector('.commentlist') && !obsAdded) {
          addObserversForReviews(document.querySelector('.commentlist'), this.props.sitecoreContext);
          obsAdded = true;
          clearInterval(interval);
        }
      }, 500);
    }

    const tagManagerArgs = {
      gtmId: 'GTM-K46PBS',
    };

    TagManager.initialize(tagManagerArgs);
  }

  getCurrentPageCategory() {
    let pageCategory = this.props.sitecoreContext.route.fields.pageCategoryGTM.value;
    if (this.props.sitecoreContext.route.templateName === 'ProductRoute') pageCategory = 'product detail page';
    else if (this.props.sitecoreContext.route.templateName === 'ArticleRoute') pageCategory = 'content page::article';
    else if (this.props.sitecoreContext.route.templateName === 'ProductFinderRoute') pageCategory = 'product selector page';

    return pageCategory;
  }

  componentWillUnmount() {
    window.removeEventListener('load', () => addObserversForProducts(this.props.sitecoreContext));
  }

  // ON PAGE CHANGE
  updateDataLayerPageview() {
    addObserversForProducts(this.props.sitecoreContext);
    this.productDetailsPage();

    let pageCategory = this.getCurrentPageCategory();
    if (pageCategory === 'content page::article') this.readArticleEvent(this.props.sitecoreContext.route.displayName);

    window.dataLayer = window.dataLayer || [];
    var json = {
      event: 'updatevirtualpath',
      brand: 'CAD',
      language: 'fr',
      country: 'FR',
      siteTypeLevel: 'main',
      pageCategory: pageCategory,
      virtualPageUrl: window.location.pathname,
      virtualPageTitle: this.props.sitecoreContext.route.displayName,
    };
    window.dataLayer.push(json);

    // Update pageCategory for first event
    const idx = window.dataLayer.findIndex((evt) => evt.event == "variablePage")
    window.dataLayer[idx].pageCategory = pageCategory
  }

  // PRODUCT DETAILS
  productDetailsPage() {
    if (this.props.sitecoreContext.route.templateName === 'ProductRoute') {
      const name = this.props.sitecoreContext.route.displayName;
      const id = this.props.sitecoreContext.route.fields.codeEan?.value;
      const category = this.props.sitecoreContext.route.fields.productCategories[0]?.fields.Title.value;
      const rating = parseFloat(this.props.sitecoreContext.route.fields.rating?.value);

      window.dataLayer.push({
        event: 'nievent',
        event_name: 'view_item',
        eventCategory: 'Ecommerce',
        eventAction: 'Product Detail',
        eventLabel: `${name}::${id}`,
        product_info: `${name}::${id}`,
        ecommerce: {
          detail: {
            products: [
              {
                name: name,
                id: id,
                brand: 'CAD',
                category: category,
                dimension38: rating,
                dimension48: id,
              },
            ],
          },
        },
      });
    }
  }

  // ARTICLE READ EVENT
  readArticleEvent(articleName) {
    let eventSent = false;

    setTimeout(function () {
      if (!eventSent) addToDatalayer();
    }, 30000);

    window.addEventListener('scroll', function () {
      if (getScrollPercent() >= 60 && !eventSent) addToDatalayer();
    });

    function addToDatalayer() {
      window.dataLayer.push({
        event: 'uaevent',
        event_name: 'read_article',
        ecommerce: 'undefined',
        eventCategory: 'content page::article',
        eventAction: 'read article',
        eventLabel: articleName,
        article_name: articleName,
      });
      eventSent = true;
    }

    function getScrollPercent() {
      var h = document.documentElement,
        b = document.body,
        st = 'scrollTop',
        sh = 'scrollHeight';
      return ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    Object.entries(this.props).forEach(([key, val]) => {
      if (prevProps[key] !== val) {
        if (key == 'pageTitle') {
          this.updateDataLayerPageview();
        }
      }
    });
  }

  render() {
    return <div></div>;
  }
}

export default withSitecoreContext()(Tagging);

export function observeProductFinder(context) {
  addObserversForProducts(context);
}

// PRODUCT CLICK
export function productClickTagging(name, id, category, rating, list, position) {
  window.dataLayer.push({
    event: 'productClick',
    eventCategory: 'Ecommerce',
    eventAction: 'Product Click',
    eventLabel: `${name}::${id}`,
    event_name: 'select_item',
    ecommerce: {
      click: {
        actionField: [
          {
            list: list,
          },
        ],
        products: [
          {
            name: name,
            id: id,
            brand: 'CAD',
            category: category,
            position: position,
            dimension38: parseFloat(rating).toFixed(2),
            dimension48: id,
          },
        ],
      },
    },
  });
}

// CLICK ON BUY BUTTON
export function handleClickToBuy(name, id) {
  window.dataLayer.push({
    event: 'uaevent',
    ecommerce: 'undefined',
    eventCategory: 'Ecommerce',
    eventAction: 'click to buy',
    eventLabel: `${name}::${id}`,
    event_name: 'buy_now',
    product_info: `${name}::${id}`,
  });
}

// WRITE A REVIEW (BUTTON)
export function handleWriteReview(name, id) {
  window.dataLayer.push({
    event: 'uaevent',
    event_name: 'write_review',
    ecommerce: 'undefined',
    eventCategory: 'product detail page',
    eventAction: 'write review',
    eventLabel: `${name}::${id}`,
    product_info: `${name}::${id}`,
  });
}

// SUBMIT A REVIEW
export function handleSubmitReview(name, id) {
  window.dataLayer.push({
    event: 'uaevent',
    event_name: 'submit_review',
    ecommerce: 'undefined',
    eventCategory: 'product detail page',
    eventAction: 'submit a review',
    eventLabel: `${name}::${id}`,
    product_info: `${name}::${id}`,
  });
}

// INTERNAL SEARCH
export function handleInternalSearch(keyword) {
  window.dataLayer.push({
    event: 'uaevent',
    ecommerce: 'undefined',
    event_name: 'internal_search',
    eventCategory: 'internal search',
    eventAction: 'search::manual',
    eventLabel: keyword,
    search_keyword: keyword,
    method: 'manual',
  });
}

// CLICK ON FILTERS
export function handleFilterClick(filtername, filtervalue) {
  window.dataLayer.push({
    event: 'uaevent',
    event_name: 'use_filters',
    ecommerce: 'undefined',
    eventCategory: 'product selector page',
    eventAction: 'filter',
    eventLabel: filtername,
    filter_type: 'filter',
    filter_value: filtername + '::' + filtervalue,
  });
}

// NAVIGATION MENUS
export function handleNavigationClick(area, label) {
  window.dataLayer.push({
    event: 'uaevent',
    event_name: 'menu_click',
    ecommerce: 'undefined',
    eventCategory: 'main menu navigation',
    eventAction: 'select::' + area,
    eventLabel: label,
    click_area: area,
    breadcrumb: label,
  });
}

// SLIDER BUTTONS
export function handleSliderButtonClick(pageCategory, slideTitle) {
  window.dataLayer.push({
    event: 'uaevent',
    event_name: 'slider_button_click',
    ecommerce: 'undefined',
    eventCategory: pageCategory,
    eventAction: 'select::slider navigation',
    eventLabel: slideTitle + '::none',
    cta_name: slideTitle,
    link_url: 'none',
  });
}

//LOGO CLICK
export function handleLogoClick() {
  window.dataLayer.push({
    event: 'uaevent',
    event_name: 'logo_click',
    ecommerce: 'undefined',
    eventCategory: 'main menu navigation',
    eventAction: 'select',
    eventLabel: 'logo',
  });
}

// READ REVIEW
function addObserversForReviews(elem, context) {
  if (elem) {
    const productName = context.route.displayName;
    const productId = context.route.fields.codeEan.value;

    let observer = new IntersectionObserver(
      function (entries) {
        if (entries[0].isIntersecting === true) {
          window.dataLayer.push({
            event: 'uaevent',
            ecommerce: 'undefined',
            event_name: 'read_review',
            eventCategory: 'product detail page',
            eventAction: 'read review',
            eventLabel: `${productName}::${productId}`,
            product_info: `${productName}::${productId}`,
          });

          observer.unobserve(entries[0].target);
        }
      },
      { threshold: [0.1] }
    );
    observer.observe(elem);
  }
}

//PRODUCT IMPRESSION
function addObserversForProducts(context) {

  let eventCreated = false;
  let tempData = []
  let pageCategory = context.route.fields.pageCategoryGTM.value;
      if (context.route.templateName === 'ProductRoute') pageCategory = 'product detail page';
      else if (context.route.templateName === 'ArticleRoute') pageCategory = 'content page::article';
      else if (context.route.templateName === 'ProductFinderRoute') pageCategory = 'product selector page';

  setTimeout(function () {

      document.querySelectorAll('.productTag').forEach(function (elem) {
          let observer = new IntersectionObserver(
              function (entries) {

                  if (entries[0].isIntersecting === true) {

                      const name = entries[0].target.getAttribute('data-gtm-name');
                      const eanCode = entries[0].target.getAttribute('data-gtm-ean');
                      const productCategory = entries[0].target.getAttribute('data-gtm-category');
                      const productRating = parseFloat(entries[0].target.getAttribute('data-gtm-rating')).toFixed(2);
                      const list = entries[0].target.getAttribute('data-gtm-list');
                      const position = parseFloat(entries[0].target.getAttribute('data-gtm-position'));

                      const data = {
                          name: name,
                          id: eanCode,
                          brand: 'CAD',
                          list: list,
                          position: position,
                          category: productCategory,
                          dimension38: productRating,
                          dimension48: eanCode,
                      }
                      tempData.push(data)
                      
                      setTimeout(function(){
                          pushEvent()
                      }, 100)
                      

                      observer.unobserve(entries[0].target);
                  }
              },
              { threshold: [0.25] }
          );

          observer.observe(elem);

      });
  }, 2500);

  function pushEvent(){
      if (!eventCreated) {

          window.dataLayer.push({
              event: 'nievent',
              eventCategory: 'Ecommerce',
              eventAction: 'Product Impressions',
              eventLabel: pageCategory,
              event_name: 'view_item_list',
              ecommerce: {
                  currencyCode: 'EUR',
                  impressions: tempData,
              },
          });
          tempData = []
          setTimeout(function () {
              eventCreated = false;
          }, 100);
      }

      eventCreated = true;
  }
}